<template>
  <div>
    <basic-container>
        <el-dialog title="编辑"
                :visible.sync="editFormVisible"
                width="50%"
                :append-to-body="true">
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                         <el-form-item label="编号" prop="name">
                            <el-input v-model="ruleForm.code"></el-input>
                        </el-form-item>
                        <el-form-item  label="所属工段" prop="region">
                            <el-select v-model="ruleForm.workshopSection" >
                             <el-option v-for="item in workshopSectionList" :key="item.id" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属车间" >
                            <el-select v-model="ruleForm.fromList" multiple >
                            <el-option v-for="item in workshopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态" prop="statusChange">
                            <el-switch v-model="ruleForm.status"></el-switch>
                        </el-form-item>
                         <el-form-item label="排序" prop="sort">
                            <el-input-number v-model="ruleForm.sort"></el-input-number>
                        </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="editFormVisible = false">取消</el-button>
                            <el-button type="primary" @click="confirm">确 定</el-button>
                        </div>
                </el-dialog>
          <!-- <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="80%"
                :append-to-body="true">
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </p>
    </el-dialog> -->
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="changeRow(scope)">
                </el-switch>
            </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.PROCESSEXPORT"
              :innerAPI="this.proApis.PROCESSIMPORT"
              :API="this.proApis.PROCESSIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
            <el-input v-model="nameOrIdLike" :placeholder="$t('OPERATION NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <!-- <template slot-scope="scope" slot="menu">
   
</template> -->
        <template slot="menu" scope="scope">
            <!-- <div  style="height:30px;" v-if="scope.row.status == 0"/>
            <el-button v-show="false" type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button> -->
              <el-button type="text"
               size="small"
               icon="el-icon-edit"
               class="none-border"
               @click.stop="beforeOpen(scope.row)">编辑
    </el-button>
        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,changeStatus,add,edit,remove,audit,selectList} from "@/api/wkln"
    import importBtn from "@/components/importExport/importBtn";
    import { getDictName } from "@/api/config";

export default {
  components:{
    importBtn
  },
    data() {
        return {
            editFormVisible:false,
            formLabelWidth:110,
             
             ruleForm: {
                name: '',
                code:'',
                workshopSection: '',
                fromList: [],
                status:false,
                sort:''
        },
            rules: {
                name: [
                { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                code: [
                { required: true, message: '请选择编号', trigger: 'change' }
                ]
            },
            workshopSectionList:[],//所属工段数组
            workshopList: [], //所属车间数组
            tableLoading: false,
            selectionList: [],
            nameOrIdLike: "",
            status: "0",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                editBtn:false,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('NAME'),
                    prop: "name",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('CODE'),
                    prop: "code",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('BELONGING WORKSHOP SECTION'),
                    //hide:true,
                    type: "select",
                    prop: "type",
                    props: {
                        label: "name",
                        value: "name"
                    },
                    dicData: [],
                },
                 {
                   label: this.$t('BELONGING WORKSHOP'), 
                   display: false,
                   hide:true,
                    props: {
                        label: "name",
                        value: "id"
                    },
                    dicData: [],
                //    formatter(row) {
                //        let str = ''
                //     //    console.log(row.deptProcessResponseList);
                //        if (row.$deptProcessResponseList!= null) {
                           
                //            row.$deptProcessResponseList.forEach(item => {
                //                 //   return row.deptProcessResponseList[0].departmentName
                //                str = str + item.departmentName + ","
                //             // str.push(item.departmentName)
                //            })
                //            str = str.substr(0,str.length-1)
                //                return str
                //        }
                //      },
                },
                {
                    label: this.$t('BELONGING WORKSHOP'),
                    multiple:true,
                    // addDisplay:true,
                    type: "select",
                    prop: "deptProcessResponseList",
                    // disabled:true,
                      formatter(row,value,label,column) {
                        //   console.log(row,value,label,column);
                       let str = ''
                    //    console.log(row.deptProcessResponseList);
                       if (row.deptProcessResponseList!= null) {
                           
                           row.deptProcessResponseList.forEach(item => {
                                //   return row.deptProcessResponseList[0].departmentName
                               str = str + item.departmentName + ","
                            // str.push(item.departmentName)
                           })
                           str = str.substr(0,str.length-1)
                               return str
                       }
                     },
                   
                }
              /*,{
                label: "是否主瓶颈",
                prop: "cod"
              },{
                label: "是否计划",
                prop: "cod"
              },{
                label: "是否排产",
                prop: "cod"
              },{
                label: "数据状态",
                prop: "cod"
              }
              */, {
                    label: this.$t('STATE'),
                    prop: "status",
                    type: "switch",
                    slot: true,
                    dicData: [{
                        label: this.$t('ENABLE'),
                        value: 0
                    }, {
                        label: this.$t('DISABLE'),
                        value: 1
                    }]
                }, {
                    label: this.$t('AUDIT STATUS'),
                    hide: true,
                    addDisplay: false,
                    editDisplay: false,
                    prop: "auditStatus",
                    type: "select",
                    dicData: [{
                        label: this.$t('REVIEWED'),
                        value: 1
                    }, {
                        label: this.$t('TO BE REVIEWED'),
                        value: 0
                    }, {
                        label: this.$t('AUDIT FAILED'),
                        value: -1
                    }]
                }, {
                    label: this.$t('SORT'),
                    value: 0,
                    type: "number",
                    sortable: true,
                    prop: "sort"
                }, {
                    label: this.$t('CREATTIME'),
                    editDisplay: false,
                    addDisplay: false,
                    prop: "createTime"
                }]
            }
        }
    },
        created() {
            getDictName().then(res => {
                console.log(res)
                this.workshopSectionList= res.data.data;
            });
            selectList().then(res => {
                console.log(res)
                this.workshopList = res.data.data;
            }); 
      this.list();
    },
    methods: {
        confirm() {
            let obj = {
                name: this.ruleForm.name,
                code: this.ruleForm.code,
                dictName: this.ruleForm.workshopSection,
                sort: this.ruleForm.sort,
                departmentIdList: this.ruleForm.fromList,
                id:this.id
            }
               edit(obj).then(res => {
               if(res.data.code=="0000"){
                    this.$message({
                    message: "修改成功",
                    type: "success"
                     });
                }
                // res.data.code != "BUSINESS.0001" ? this.$message({
                //     message: this.$t('OperationSuccess'),
                //   type: "success"
                // }):this.$message({
                //     message: this.$t('OperationFailed'),
                //   type: "error"
                // });
                this.list();
            })
            this.editFormVisible = false
        },
        beforeOpen(row) {
            this.editFormVisible = true
            console.log(row);
            this.id = row.id
            this.ruleForm.name = row.name;
            this.ruleForm.code = row.code;
            this.ruleForm.workshopSection = row.type
            this.ruleForm.status = row.status
            this.ruleForm.sort = row.sort
            if (row.status == 0) {
                this.ruleForm.status = true
            }else {
                 this.ruleForm.status = false
            }
            this.ruleForm.fromList = []
            row.deptProcessResponseList.forEach(item => {
                this.ruleForm.fromList.push(item.departmentId)
            })
        },
        refreshData() {
          this.list();
        },        currentChange(pageCurrent) {
              this.page.pageCurrent = pageCurrent;
              this.list();
          },
        sizeChange(pageSize) {
            this.page.pageCurrent = 1;
            this.page.pageSize = pageSize;
            this.list();
        },
        // 操作栏的显示隐藏
        // getPermission(key, row, index) {
        //   console.log('000',key,row,index);
        //   if (key==='delBtn' && row.status === 0 ) {
        //     return false;
        //   } else if (key==='editBtn' && row.status === 0 ){
        //     return false;
        //   }
        //   return true;
        // },
        audit() {
          audit(this.selectionList[0].id,1).then(res => {
            res.data.code != "BUSINESS.0001" ? this.$message({
                message: this.$t('AUDIT SUCCEEDED'),
              type: "success"
            }):this.$message({
                message: this.$t('AUDIT FAILED'),
              type: "success"
            });
            this.list();
          })
        },
        returnAudit() {
          audit(this.selectionList[0].id,0).then(res => {
            this.$message({
                message: this.$t('DE APPROVAL SUCCEEDED'),
              type: "success"
            });
            this.list();
          })
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(() => {
                    this.list();
                    this.$message.success('删除成功')
                })
            })
        },
        // handleUpdate(row, index, done) {
        //     console.log(1)
        //     console.log(row)
        //     edit(row).then(res => {
        //        if(res.data.code=="0000"){
        //             this.$message({
        //             message: "修改成功",
        //             type: "success"
        //              });
        //         }
        //         // res.data.code != "BUSINESS.0001" ? this.$message({
        //         //     message: this.$t('OperationSuccess'),
        //         //   type: "success"
        //         // }):this.$message({
        //         //     message: this.$t('OperationFailed'),
        //         //   type: "error"
        //         // });
        //         this.list();
        //         done();
        //     })
        // },
        handleSave(row, done) {
            console.log(row)
            add(row).then(() => {
                this.list();
                done();
                this.$message.success('新增成功')
            })
        },
        changeRow(row) {
          console.log('change',row);
            changeStatus(row)
        },
        selectionChange(list) {
            this.selectionList = list;
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrIdLike).then(res => {
              this.page.total = res.data.data.total;
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  //v.type = Number(v.type);
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>